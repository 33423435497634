import React, { FC } from 'react';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { INLINES } from '@contentful/rich-text-types';

import Logo from 'components/atoms/Logo';

import { useScreenService } from 'hooks/useScreenService';

import {
  StyledCompanyAdrressFooter,
  StyledCompanyFooter,
  StyledTypography,
} from './CompanyFooter.styles';

import { CompanyFooterProps } from './models.d';

const options = {
  renderNode: {
    [INLINES.HYPERLINK]: ({ data }, children) => (
      <a target="_blank" className="link" data-target="hover" href={data.uri} rel="noreferrer">
        {children}
      </a>
    ),
  },
};

const CompanyFooter: FC<CompanyFooterProps> = ({ address, addressMobile, contactInfo, logo }) => {
  const { isLgUp } = useScreenService();

  return (
    <StyledCompanyFooter data-testid="company-footer">
      {logo ? <Logo {...logo} /> : null}
      <StyledCompanyAdrressFooter>
        {isLgUp ? (
          <>
            <StyledTypography color="gray-25" size={16} fontWeight={400} lineHeight={1.4}>
              {renderRichText(address)}
            </StyledTypography>

            <StyledTypography color="gray-25" size={16} fontWeight={400} lineHeight={1.4}>
              {renderRichText(contactInfo, options)}
            </StyledTypography>
          </>
        ) : (
          <StyledTypography color="gray-25" size={14} fontWeight={300} lineHeight={1.4}>
            {renderRichText(addressMobile, options)}
          </StyledTypography>
        )}
      </StyledCompanyAdrressFooter>
    </StyledCompanyFooter>
  );
};

export default CompanyFooter;
