import styled from 'styled-components';
import { borderBottom, breakpoint } from 'styles';

import Typography from 'components/atoms/Typography';

export const StyledCompanyFooter = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--gap-sm);
  align-items: center;
  order: 1;
  padding: var(--gap-lg) 0;

  ${breakpoint.md} {
    align-items: flex-start;
    order: 0;
  }

  ${breakpoint.lg} {
    border-right: 1px solid var(--c-gray-700);
  }

  ${borderBottom}
`;

export const StyledCompanyAdrressFooter = styled.div`
  display: flex;
  flex-direction: column;

  ${breakpoint.lg} {
    gap: var(--gap-md);
  }

  ${breakpoint.xl} {
    flex-direction: row;
    gap: var(--gap-xxxxxxl);
    justify-content: space-between;
  }

  p:first-child {
    a {
      font-weight: 500;
      text-decoration: underline;
    }
  }
`;

export const StyledTypography = styled(Typography)`
  p {
    padding-top: var(--gap-xxxxs);
    text-align: center;

    ${breakpoint.md} {
      text-align: left;
    }
  }
`;
